import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  toggled = false;
  _hasBackgroundImage = true;
  menus = [
    {
      title: 'general',
      type: 'header'
    },
    {
        title: 'Dashboard',
        icon: 'fa fa-chart-line',
        active: false,
        type: 'simple',
        link:'/dashboard',
        href:'#'
      },
    {
      title: 'Training Material',
      icon: 'fa fa-tachometer-alt',
      active: false,
      type: 'dropdown',
      link:'#',
      badge: {
        text: 'New ',
        class: 'badge-warning'
      },
      submenus: [
        {
          title: 'Selected Saqas',
          link:'/selected',
          badge: {
            text: 'Pro ',
            class: 'badge-success'
          }
        },
        
      ]
    },
    {
      title: 'Accreditation Application',
      icon: 'fa fa-shopping-cart',
      active: false,
      type: 'simple',
      href:'#'
    },
    {
      title: 'Buisness Detail',
      icon: 'far fa-gem',
      active: false,
      type: 'simple',
      link:'/buisness-detail',
      href:'#'
    },
    {
      title: 'StakeHolder',
      icon: 'fa fa-chart-line',
      active: false,
      type: 'simple',
      href:'#'
    },
    {
      title: 'Program Detail',
      icon: 'fa fa-globe',
      active: false,
      type: 'simple',
      href:'#'
    },
    // {
    //   title: 'Extra',
    //   type: 'header'
    // },
    {
      title: 'Contact Detail',
      icon: 'fa fa-book',
      active: false,
      type: 'simple',
      badge: {
        text: 'Beta',
        class: 'badge-primary'
      },
    },
    {
      title: 'legal Documents',
      icon: 'fa fa-calendar',
      active: false,
      type: 'simple',
      link:'user-finish/legal-documents'
    },
    {
      title: 'qms',
      icon: 'fa fa-folder',
      active: false,
      type: 'simple',
      link:'user-finish/qms'
    },
    {
        title: 'Files',
        icon: 'fa fa-folder',
        active: false,
        type: 'simple',
        link:'user-finish/File-approve'
      },
      
      {
        title: 'Teachers',
        icon: 'fa fa-tachometer-alt',
        active: false,
        type: 'dropdown',
        badge: {
          text: 'New ',
          class: 'badge-warning'
        },
        submenus: [
          {
            title: 'Facilitators',
            badge: {
              text: 'Pro ',
              class: 'badge-success'
            },
            link:'user-finish/facilitators'
          },
          {
            title: 'Moderators',
            badge: {
              text: 'Pro ',
              class: 'badge-success'
            },
            link:'user-finish/moderator'
          },
          {
            title: 'Accessors',
            badge: {
              text: 'Pro ',
              class: 'badge-success'
            }
          }
          
        ]
      }
  ];
  constructor() { }

  toggle() {
    this.toggled = ! this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  getMenuList() {
    return this.menus;
  }

  get hasBackgroundImage() {
    return this._hasBackgroundImage;
  }

  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }
}