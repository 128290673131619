import { Component, OnInit  , ViewChild} from '@angular/core';
import { Router, NavigationEnd  } from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-step-header',
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.scss']
})
export class StepHeaderComponent implements OnInit {
  @ViewChild(PerfectScrollbarComponent, { static: false }) perfectScrollbar: PerfectScrollbarComponent;

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  isLinear = false;
  currentStep: string;
  
  constructor(private route:ActivatedRoute,
    private _formBuilder: FormBuilder,
    private router:Router,
    ) { }

    ngOnInit() {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentStep = this.getCurrentStep();
          console.log(this.currentStep);
        }
      });
    }
    

    initializePerfectScrollbar(): void {
      if (this.perfectScrollbar) {
        const ps = this.perfectScrollbar.directiveRef;
        ps.update();
      }
    }

    private getCurrentStep(): string {
      let currentStep = '';
      const path = this.route.snapshot.routeConfig?.path ?? '';
      
      switch (path) {
        case 'contact-detail':
          currentStep = 'contact-detail';
          break;
        case 'buisness-detail':
          currentStep = 'buisness-detail';
          break;
        case 'facilitator':
          currentStep = 'facilitator';
          break;
        case 'moderator':
          currentStep = 'moderator';
          break;
        case 'accreditation-type':
          currentStep = 'accreditation-type';
          break;
        case 'file-approve':
          currentStep = 'file-approve';
          break;
        case 'program-detail':
          currentStep = 'program-detail';
          break;
        default:
          currentStep = '';
          break;
      }
      
      return currentStep;
    }
  
  }

  
  
  
  
  
  
  
    
    
  

    
  

 
  
  
  
  
  
  


