import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-finish',
  templateUrl: './layout-finish.component.html',
  styleUrls: ['./layout-finish.component.scss']
})
export class LayoutFinishComponent implements OnInit {
  lead: string; // Add the 'lead' property

  constructor() { }

  ngOnInit(): void {
  }

}
