import { Component, OnInit } from '@angular/core';
import { SecurityService } from '@core/security/security.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number;
  constructor() { }

  ngOnInit(): void {
    this.currentYear= new Date().getFullYear();
   
  }

  

}
