import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TruncatePipe } from './truncate.pipe';
import { DateAgoPipe } from './date-ago.pipe';


import { UTCToLocalTime } from './utc-to-localtime.pipe';




@NgModule({
    declarations: [
        TruncatePipe,
        DateAgoPipe,
    
        UTCToLocalTime,
       
    ],
    imports: [
        CommonModule
    ],
    exports: [
        TruncatePipe,
        DateAgoPipe,
      
        UTCToLocalTime
    ],
    providers: []
})
export class PipesModule { }
