<!-- MultiStep Form -->
<div class="container-fluid" id="grad1">
    <div class="row justify-content-center mt-0">
      <div class="col-11 col-sm-9 col-md-7 col-lg-6 text-center p-0 mt-3 mb-2">
        <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
          <h2><strong>SKILLS</strong></h2>
          <p>Fill all form fields to go to the next step</p>
          <div class="row">
            <div class="col-md-12 mx-0">
              <form id="msform" perfect-scrollbar>
                <!-- progressbar -->
                <perfect-scrollbar [config]="{ suppressScrollX: false }">
                  <ul id="progressbar">
                    <li id="account" routerLink="contact-detail" [class.active]="currentStep === 'contact-detail'">
                      <span class="circle"><i class="fas fa-user"></i></span>
                      <br>
                      <strong>Contact Detail</strong>
                    </li>
                    <li id="personal" routerLink="buisness-detail" [class.active]="currentStep === 'buisness-detail'">
                      <span class="circle"><i class="fas fa-building"></i></span>
                      <br>
                      <strong>Business Details</strong>
                    </li>
                    <li id="personal" routerLink="accreditation-type" [class.active]="currentStep === 'accreditation-type'">
                      <span class="circle"><i class="fas fa-award"></i></span>
                      <br>
                      <strong>Accreditation</strong>
                    </li>
                    <li id="personal" routerLink="program-detail" [class.active]="currentStep === 'program-detail'">
                      <span class="circle"><i class="fas fa-book"></i></span>
                      <br>
                      <strong>Program Detail</strong>
                    </li>
                    <li id="payment" routerLink="file-approve" [class.active]="currentStep === 'file-approve'">
                        <span class="circle"><i class="fas fa-file"></i></span>
                        <br>
                        <strong>Files</strong>
                      </li>

                      <li id="payment" routerLink="qms" [class.active]="currentStep === 'qms'">
                        <span class="circle"><i class="fas fa-book"></i></span>
                        <br>
                        <strong>Qms</strong>
                      </li>

                    <li id="payment" routerLink="facilitator" [class.active]="currentStep === 'facilitator'">
                      <span class="circle"><i class="fas fa-users"></i></span>
                      <br>
                      <strong>Facilitator</strong>
                    </li>

                    <li id="payment" routerLink="moderator" [class.active]="currentStep === 'facilitator'">
                        <span class="circle"><i class="fas fa-users"></i></span>
                        <br>
                        <strong>Moderator</strong>
                      </li>
                      <li id="payment" routerLink="accessor" [class.active]="currentStep === 'facilitator'">
                        <span class="circle"><i class="fas fa-users"></i></span>
                        <br>
                        <strong>Accessor</strong>
                      </li>

                      <li id="payment" routerLink="training-material/list" [class.active]="currentStep === 'facilitator'">
                        <span class="circle"><i class="fas fa-book"></i></span>
                        <br>
                        <strong>Training Material</strong>
                      </li>
                  </ul>
                </perfect-scrollbar>
                <!-- fieldsets -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  