import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/security/auth.guard';
import { AppComponent } from './app.component';
import { LayoutFinishComponent } from '@core/layout-finish/layout-finish.component';
import { LayoutComponent } from './core/layout/layout.component';


const routes: Routes = [
  {
    path: 'user-finish',
    component: LayoutFinishComponent,  
    children: [
      {
        path: 'dashboard',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(
            m => m.DashboardModule
          )
      },
      {
        path: 'accessor',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./accessor/accessor.module').then(
            m => m.AccessorModule
          )
      },
      
      {
        path: 'accreditation-type',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./accreditation-type/accreditation-type.module').then(
            m => m.AccreditationModule
          )
      },
      {
        path: 'contact-detail',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./contact-details/contact-detail.module').then(
            m => m.ContactDetailsModule
          )
      },
      {
        path: 'stakeholder',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./stakeholder/stakeholder.module').then(
            m => m.StakeHolderModule
          )
      },
      {
        path: 'facilitator',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./facilitator/facilitator.module').then(
            m => m.FacilitatorModule
          )
      },
      {
        path: 'moderator',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./moderator/moderator.module').then(
            m => m.ModeratorModule
          )
      },
      {
        path: 'buisness-detail',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./buisness-detail/buisness-detail.module').then(
            m => m.BuissnessDetailModule
          )
      },
      {
        path: 'program-detail',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./program-detail/program-detail.module').then(
            m => m.ProgramDetailModule
          )
      },
      {
        path: 'file-approve',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./file-approve/file-approve.module').then(
            m => m.FileApproveModule
          )
      },
      
      {
        path: 'training-material',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./training-material/training-material.module').then(
            m => m.TrainingMaterialModule
          )
      },
      {
        path: 'qms',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./qms/qms.module').then(
            m => m.QmsModule
          )
      },
      {
        path: 'legal-documents',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./legal-documents/legal-documents.module').then(
            m => m.LegalDocumentModule
          )
      }
    ]
  },
  {
    path: '',
    component: AppComponent,
    children: [{
      path: 'login',
      loadChildren: () =>
        import('./login/login.module')
          .then(m => m.LoginModule)
    },
    {
      path: 'sign-up',
      loadChildren: () =>
        import('./sign-up/sign-up.module').then(
          m => m.SignUpModule
        )
    }, 
    {
      path: 'terms',
      loadChildren: () =>
        import('./terms/terms.module').then(
          m => m.TermsModule
        )
    },
   
    
    {
      path: '',
      component:LayoutComponent ,
      children: [ 
        {
          path: 'accreditation-type',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./accreditation-type/accreditation-type.module').then(
              m => m.AccreditationModule
            )
        },
        {
          path: 'contact-detail',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./contact-details/contact-detail.module').then(
              m => m.ContactDetailsModule
            )
        },
        {
          path: 'stakeholder',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./stakeholder/stakeholder.module').then(
              m => m.StakeHolderModule
            )
        },
        {
          path: 'facilitator',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./facilitator/facilitator.module').then(
              m => m.FacilitatorModule
            )
        },
        {
          path: 'moderator',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./moderator/moderator.module').then(
              m => m.ModeratorModule
            )
        },
        {
          path: 'buisness-detail',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./buisness-detail/buisness-detail.module').then(
              m => m.BuissnessDetailModule
            )
        },
        {
          path: 'program-detail',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./program-detail/program-detail.module').then(
              m => m.ProgramDetailModule
            )
        },
        {
          path: 'file-approve',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./file-approve/file-approve.module').then(
              m => m.FileApproveModule
            )
        },
        
        {
          path: 'training-material',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./training-material/training-material.module').then(
              m => m.TrainingMaterialModule
            )
        },
        {
          path: 'qms',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./qms/qms.module').then(
              m => m.QmsModule
            )
        },
        {
          path: 'accessor',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./accessor/accessor.module').then(
              m => m.AccessorModule
            )
        },
        {
          path: 'legal-documents',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./legal-documents/legal-documents.module').then(
              m => m.LegalDocumentModule
            )
        },
        {
          path: 'last-step',
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('./last-step/last-step.module').then(
              m => m.LastStepModule
            )
        },
        {
          path: '**',
          redirectTo: '/'
        },

      ]
    },]

    

    


  }
  
];


@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
