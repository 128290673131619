<div class="topbar">
  <nav class="navbar header-nav navbar-expand-lg navbar-custom">
      <div class="topbar-left" [class.display-none]="isFromPos">
          <a [routerLink]="['/']" class="logo">
              <span>
                  <img *ngIf="!logoImage" src="assets/images/jh-logo.png" alt="logo-large" class="logo-lg">
                  <img *ngIf="logoImage" [src]="logoImage" alt="logo-large" class="logo-lg">
              </span>
          </a>
      </div>
     
        
         
          <div class="col-md-auto col-auto ml-3 d-block d-md-none">
              <div>
                  <span class="notification-area">
                      <i class="far fa-bell fs-24"></i>
                      <span class="noti-count">
                          {{notificationCount}}
                      </span>
                  </span>
              </div>
          </div>
          <div class="col-md-auto col-auto">
              <button class="navbar-toggler" type="button" data-toggle="collapse"
                  data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon hamburger" style="margin-top: -10px;">
                      <span class="line"></span><span class="line"></span><span class="line"></span>
                  </span>
              </button>
          </div>
      
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="list-unstyled topbar-nav ml-auto topbar-nav-right mb-0 float-right hide-when-toogle">
             
              <li class="nav-item dropdown d-none d-md-block">
                  <span class="notification-area" data-toggle="dropdown">
                      <i class="far fa-bell fs-20 text-white"></i>
                      <span class="noti-count">
                          {{notificationCount}}
                      </span>
                  </span>
                  <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                      <span class="dropdown-item dropdown-header"></span>
                      <div class="dropdown-divider"></div>
                      
                      <a [routerLink]="['/notifications']" routerLinkActive="router-link-active"
                          class="dropdown-item dropdown-footer">{{"SEE_ALL_NOTIFICATIONS" | translate}}</a>
                  </div>
              </li>
              <li class="nav-item">
                  <a class="nav-link cursor-pointer">
                      <span class="left-fa-icon">
                          <img *ngIf="appUserAuth?.profilePhoto" [src]="profilePath" alt="User Avatar"
                              class="user-image mt-1"></span><span
                          class="mb-0 fs-14 text-capitalize">{{appUserAuth.firstName}}
                          {{appUserAuth.lastName}}</span>
                      <img *ngIf="!appUserAuth?.profilePhoto" src="assets/images/user-profile.jpg" alt="User Avatar"
                          class="user-image mt-1">
                  </a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" (click)="onLogout()">
                      <span class="left-fa-icon"><i class="fas fa-sign-out-alt fs-15"></i></span>
                      <span class="mb-0 fs-14 text-capitalize">{{'LOGOUT' | translate}}</span>
                  </a>
              </li>
          </ul>
      </div>
  </nav>
</div>