<nav id="sidebar" class="sidebar" [ngClass]="{'sidebar-bg' : hasBackgroundImage()}">
  <div class="sidebar-content">
    <perfect-scrollbar>
      <div class="sidebar-header">
        <a  routerLink="/user-finish">JH SKILLS</a>
      </div>

      <div class="sidebar-profile">
       
        <div class="user-info">
          <span class="user-name">{{appUserAuth.firstName}}
            <strong>{{appUserAuth.lastName}}</strong>
          </span>
          <span class="user-role">User</span>
          <span class="user-status">
            <i class="fa fa-circle"></i>
            <span>Online</span>
          </span>
        </div>
      </div>
      <div class="sidebar-search">
        <div>
          <div class="input-group">
            <input type="text" class="form-control search-menu" placeholder="Search...">
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-menu">
        <ul>
          <li>
            <span>general</span>
            <a  routerLink="/user-finish/dashboard">
              <i></i>
              <span>Dashboard</span>
            
            </a>
            
          </li>

          

          <li>
          
            <a  routerLink="/user-finish/contact-detail">
              <i></i>
              <span>Contact Detail</span>
            
            </a>
            
          </li>

          <li>
          
            <a routerLink="/user-finish/buisness-detail">
              <i></i>
              <span>Buisness Detail</span>
            
            </a>
            
          </li>


          <li>
          
            <a routerLink="/user-finish/stakeholder">
              <i></i>
              <span>Stake Holder</span>
            
            </a>
            
          </li>


          <li>
          
            <a routerLink="/user-finish/program-detail">
              <i></i>
              <span>Program Detail</span>
            
            </a>
            
          </li>


          <li>
          
            <a routerLink="/user-finish/facilitator">
              <i></i>
              <span>Facilitators</span>
            
            </a>
            
          </li>


          <li>
          
            <a routerLink="/user-finish/moderator">
              <i></i>
              <span>Moderators</span>
            
            </a>
            
          </li>


          <li>
          
            <a routerLink="/user-finish/accessor">
              <i></i>
              <span>Accessors</span>
            
            </a>
            
          </li>


          <li>
          
            <a routerLink="/user-finish/qms">
              <i></i>
              <span>Qms</span>
            
            </a>
            
          </li>


          <li>
          
            <a routerLink="/user-finish/file-approve">
              <i></i>
              <span>File</span>
            
            </a>
            
          </li>
          <li>
          
            <a routerLink="/user-finish/accreditation-type">
              <i></i>
              <span>Accreditation Application</span>
            
            </a>
            
          </li>

        </ul>
      </div>
    </perfect-scrollbar>
  </div>
 
</nav>

