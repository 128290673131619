import { EntityMetadataMap } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {

 
  Title: {
    entityDispatcherOptions: { optimisticDelete: false, optimisticUpsert: false }
  },
  Pio: {
    entityDispatcherOptions: { optimisticDelete: false, optimisticUpsert: false }
  },
  CompanySize:{
    entityDispatcherOptions: { optimisticDelete: false, optimisticUpsert: false }
  },
  CompanyType:{
    entityDispatcherOptions: { optimisticDelete: false, optimisticUpsert: false }
  },
  PrimarySetas:{
    entityDispatcherOptions: { optimisticDelete: false, optimisticUpsert: false }
  },
  Provinces:{
    entityDispatcherOptions: { optimisticDelete: false, optimisticUpsert: false }
  },
  Setas:{
    entityDispatcherOptions: { optimisticDelete: false, optimisticUpsert: false }
  },

  Accredidtations:{
    entityDispatcherOptions: { optimisticDelete: false, optimisticUpsert: false }
  }
  
 
};
const pluralNames = {
 
  Title:"Title",
  Pio:"PIO",
  CompanySize:"CompanySize",
 CompanyType:"CompanyType",
 PrimarySetas : "PrimarySetas",
 Provinces:"Provinces",
 Setas:"Setas",
 Accredidtations:"Accredidtations"
};

export const entityConfig = {
  entityMetadata,
  pluralNames
};
